<template>
  <v-container class="py-0 px-5">
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <logo-top />
      </v-list-item-content>
      <v-list-item-avatar tile size="80">
        <v-btn small fab dark color="#EB4646" @click.prevent="backToHome">
          <v-icon> mdi-home </v-icon>
        </v-btn>
      </v-list-item-avatar>
    </v-list-item>
    <v-card tile elevation="0">
      <v-card-text align="center">
        <v-icon size="80" color="#EB4646"> mdi-check-circle-outline </v-icon>
      </v-card-text>

      <v-card-text>
        <v-card-title
          class="px-0 justify-center text-h6 font-weight-bold black--text"
        >
          <center>Order Jual Sampah Berhasil Dibuat</center>
        </v-card-title>
        <v-card-subtitle class="px-0 pt-2 text-subtitle-2 black--text">
          <center>
            Driver kami akan segera menghubungi dan mendatangi lokasi untuk melakukan penjemputan, penimbangan dan pembayaran cash di tempat. Mohon menunggu ya!
          </center>
        </v-card-subtitle>
        <v-card-subtitle
          class="pt-0 text-subtitle-2 black--text"
          style="line-height: normal"
        >
          <center>{{ new Date() | moment("DD MMMM YYYY, H:mm") }}</center>
        </v-card-subtitle>
      </v-card-text>

      <v-list-item dense>
        <v-list-item-content>
          <span class="text-subtitle-2">Jenis Transaksi</span>
        </v-list-item-content>
        <v-list-item-content align="end">
          <span class="text-subtitle-2">Jual Sampah</span>
        </v-list-item-content>
      </v-list-item>
      <v-list-item dense>
        <v-list-item-content>
          <span class="text-subtitle-2">Metode Bayar</span>
        </v-list-item-content>
        <v-list-item-content align="end">
          <span class="text-subtitle-2">Cash</span>
        </v-list-item-content>
      </v-list-item>
      <v-list-item dense>
        <v-list-item-content>
          <span class="text-subtitle-2">Order ID</span>
        </v-list-item-content>
        <v-list-item-content align="end">
          <span class="text-subtitle-2"> #{{ this.orderID }} </span>
        </v-list-item-content>
      </v-list-item>

      <v-card-text class="pa-0">
        <v-card-title class="text-caption grey--text">
          <i>Detail transaksi dapat dilihat pada menu Riwayat Transaksi</i>
        </v-card-title>
      </v-card-text>

      <v-card-actions class="mt-5">
        <v-btn
          block
          large
          color="#25D366"
          dark
          style="text-transform: unset !important"
          @click.prevent="openWhatsapp"
        >
          <v-icon left>mdi-whatsapp</v-icon>Simpan Orderan ke Whatsapp
        </v-btn>
      </v-card-actions>

      <v-card-actions class="pb-5">
        <v-btn
          block
          large
          color="#EB4646"
          dark
          style="text-transform: unset !important"
          @click.prevent="detailTransaksi({ id: orderID })"
        >
          Lihat Status Transaksi
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import LogoTop from "../components/Home/LogoTop.vue";

export default {
  components: { LogoTop },
  metaInfo: {
    title: "Pemol",
    titleTemplate: "%s - Pilah Sampahmu!",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },
  name: "order_sukses",
  props: ["phoneNumber"],
  data: () => ({
    orderID: null,
    show: true,
  }),
  methods: {
    backToHome() {
      this.$router.push({ name: "Home" });
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
    detailTransaksi(id) {
      this.$router.push({ name: "Riwayat", params: id });
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
    openWhatsapp() {
      var text_wa = "Terimakasih telah jual sampah melalui Pemol.%0ATransaksi %23"+this.orderID+" akan segera dijemput atau dihubungi oleh driver kami. Mohon menunggu yah!%0A%0ABerikut link untuk melakukan pengecekan status orderan :%0Ahttps://order.pemol.id/%23/riwayat/"+this.orderID+"%0A%0APunya pertanyaan? Hubungi kami melalui WhatsApp di link berikut :%0Ahttps://wa.me/message/JPPSAVELYXWPJ1%0A%0A*Ini adalah pesan otomatis untuk menyimpan link resi kamu sementara";
      window.open(
        "https://wa.me/" +
          this.phoneNumber.substring(1) +
          "?text="+text_wa,
        "_blank"
      );
    },
  },
  created() {
    if (localStorage.getItem("orderId") === null) {
      this.$router.push({ name: "Home" });
    } else {
      this.orderID = localStorage.getItem("orderId");
    }
  },
};
</script>