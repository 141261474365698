<template>
  <v-container class="pa-0" fluid style="height: 100vh">
    <v-list-item class="py-0">
      <v-list-item-avatar size="28" class="mr-0">
        <v-btn text small color="#232424" @click.prevent="back(valueBack)">
          <v-icon size="20"> mdi-arrow-left </v-icon>
        </v-btn>
      </v-list-item-avatar>
      <v-list-item-content class="pa-0">
        <logo-top />
      </v-list-item-content>
    </v-list-item>

    <v-card tile elevation="0" class="pa-0">
      <v-card-title class="text-h5 font-weight-bold black--text">
        Riwayat Transaksi
      </v-card-title>

      <v-list-item>
        <v-list-item-avatar tile>
          <v-img
            contain
            eager
            lazy-src="@/assets/img/jual_sampah.svg"
            src="@/assets/img/jual_sampah.svg"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="text-subtitile-2 font-weight-bold black--text mb-1"
          >
            Jual Sampah
          </v-list-item-title>
          <v-list-item-subtitle>
            <span :class="valueClass">
              {{ this.statusOrder }}
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';
import LogoTop from "../components/Home/LogoTop.vue";
export default {
  components: { LogoTop },
  metaInfo: {
    title: "Pemol",
    titleTemplate: "%s - Riwayat",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },
  name: "Riwayat",
  props: ['id'],
  data: () => ({
    orderID: null,
    statusOrder: "",
    valueBack: "",
    valueClass: "",
  }),
  methods: {
    cekOrderId() {
      this.orderID = localStorage.getItem("orderId");
      if (this.orderID !== null) {
        this.cekStatusOrder(this.orderID);
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    cekStatusOrder(value) {
      axios
       .get('ios/transaction/'+value)
       .then(response => {
         if(response.data.data.status === '1') {
           this.statusOrder = "Sedang dalam penjemputan";
           this.valueClass = "text-caption black--text";
           this.valueBack = "1";
         }else{
           this.statusOrder = "Pesanan telah selesai";
           this.valueClass = "text-caption red--text"
           this.valueBack = "2";
         }
       })
    },
    back(value) {
      if(value === "1"){
        this.$router.push({ name: "Home" });
      }else{
        this.$router.push({ name: "Home" });
        localStorage.removeItem("orderId");
      }
    },
  },
  created() {
    this.cekOrderId();
  },
};
</script>