<template>
  <v-container class="py-0 px-5">
    <v-list-item class="px-0">
      <v-list-item-avatar size="28" class="mr-0">
        <v-btn text small color="#232424" @click.prevent="back">
          <v-icon size="20"> mdi-arrow-left </v-icon>
        </v-btn>
      </v-list-item-avatar>
      <v-list-item-content class="pa-0">
        <logo-top />
      </v-list-item-content>
    </v-list-item>
    <v-container class="px-0 pt-0">
      <v-card tile elevation="0">
        <v-card-title
          class="px-0 pt-2 pb-0 text-subtitle-1 black--text"
          style="line-height: normal"
        >
          {{ new Date() | moment("DD MMMM YYYY, H:mm") }}
        </v-card-title>
        <v-card-title class="px-2 pb-2 text-h6 font-weight-bold black--text">
          Detail Orderan
        </v-card-title>
      </v-card>

      <v-divider></v-divider>

      <v-card class="px-0 mt-2 pt-2 rounded-lg" elevation="0">
        <v-img aspect-ratio="2.2" :src="url"></v-img>
      </v-card>

      <v-card tile elevation="0">
        <v-card-title class="px-0 pb-2">
          <v-row>
            <v-col cols="2">
              <v-avatar size="24" color="#0C84F3">
                <v-icon size="14" dark> mdi-map-marker </v-icon>
              </v-avatar>
            </v-col>
            <v-col cols="10">
              <span class="text-subtitle-2 black--text">
                {{ dataTransaksi.detail_transaksi.alamat_jemput }}
              </span>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-title class="px-0 pt-0">
          <span class="text-subtitle-1 font-weight-bold black--text">
            Catatan Penjemputan
          </span>
        </v-card-title>
        <v-card-subtitle class="px-0 pt-0">
          <span class="text-subtitle-2 black--text">
            {{ dataTransaksi.detail_transaksi.catatan_alamat }}
          </span>
        </v-card-subtitle>

        <v-card-title class="px-0 mt-3">
          <span class="text-subtitle-1 font-weight-bold black--text">
            Penjemputan Langsung
          </span>
        </v-card-title>
        <v-card-subtitle class="px-0 pt-0">
          <span class="text-subtitle-2 black--text">
            {{
              dataTransaksi.detail_transaksi.tgl_jemput
                | moment("dddd, DD MMMM YYYY")
            }}, {{ setTime(dataTransaksi.detail_transaksi.waktu_jemput) }} WIB
          </span>
        </v-card-subtitle>

        <v-card-title class="px-0 mt-3">
          <v-row>
            <v-col>
              <v-avatar size="24" color="#EB4646">
                <v-icon size="14" dark> mdi-wallet </v-icon>
              </v-avatar>
              <span class="text-subtitle-2 font-weight-bold black--text">
                Cash
              </span>
            </v-col>
            <v-col align="end">
              <span class="text-subtitle-2 black--text">
                Metode Pembayaran
              </span>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="px-0 mt-5">
          <v-row class="mt-3" v-for="sampah in dataSampah" :key="sampah.jenis">
            <v-col align="start" class="py-1">
              <span class="text-subtitle-2 black--text">
                {{ sampah.jenis }}
              </span>
            </v-col>
            <v-col align="end" class="py-1">
              <span class="text-subtitle-2 font-weight-bold black--text">
                {{ sampah.berat }} Kg
              </span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="px-0 mt-5">
          <v-card-title
            class="pa-0 text-subtitle-1 font-weight-bold black--text"
          >
            Detail Pembayaran
          </v-card-title>
          <v-divider></v-divider>
          <v-sheet class="mt-3 pa-3" color="#E3F2FF" rounded="lg">
            <span class="text-subtitle-2 primary--text">
              Total harga akan diakumulasikan oleh driver Pemol ketika sampah
              sudah ditimbang di tempat kamu
            </span>
          </v-sheet>
        </v-card-text>

        <v-card-actions class="pa-0 my-3">
          <v-btn
            :loading="loading"
            :disabled="disabled"
            class="white--text"
            block
            color="#EB4646"
            style="text-transform: unset !important"
            @click.prevent="procced({ phoneNumber: dataTransaksi.detail_transaksi.no_hp })"
          >
            Proses Orderan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "vue-moment";
import LogoTop from '../components/Home/LogoTop.vue';

export default {
  components: { LogoTop },
  metaInfo: {
    title: "Pemol",
    titleTemplate: "%s - Detail Order",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },
  name: "detail_order",
  data: () => ({
    loading: false,
    disabled: false,
    url: "",
    dataSampah: [],
    dataTransaksi: {},
  }),
  methods: {
    moment: function () {
      moment.locale("id");
      return moment();
    },
    setTime(value) {
      if (value === "Pagi") {
        return "08:00 - 12:00";
      } else if (value === "Siang") {
        return "12:00 - 14:00";
      } else {
        return "15:00 - 17:00";
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    procced(param) {
      this.loading = true;
      this.disabled = true;
      var file = this.dataURLtoFile(this.url, "img.jpeg");
      const fd = new FormData();
      fd.append("image", file);
      fd.append("flag", "transaksi");
      axios.post("ios/upload-photo", fd).then((response) => {
        if (response.data.value === "1") {
          this.dataTransaksi.detail_transaksi.photo = response.data.image_name;
          this.saveDataTransaksi(param);
        }
      });
    },
    saveDataTransaksi(param) {
      axios
        .post("ios/create-transaksi", this.dataTransaksi)
        .then((response) => {
          this.loading = false;
          this.disabled = false;
          localStorage.setItem("orderId", response.data.transaction);
          localStorage.removeItem("dataSampah");
          localStorage.removeItem("img");
          localStorage.removeItem("dataTransaksi");
          this.$router.push({ name: "order_sukses", params: param });
          window.scrollTo({
            top: 0,
            left: 0,
          });
        });
    },
    back() {
      this.$router.push({ name: "Order" });
    }
  },
  created() {
    this.dataSampah = JSON.parse(localStorage.getItem("dataSampah"));
    this.dataTransaksi = JSON.parse(localStorage.getItem("dataTransaksi"));
    this.url = localStorage.getItem("img");
    if (this.dataSampah === null) {
      this.$router.push({ name: "Home" });
    }
    if (this.dataTransaksi === null) {
      this.$router.push({ name: "Home" });
    }
    if (this.url === null) {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>